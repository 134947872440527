<template>
    <div class="credit-evaluation-form">
        <div class="credit-evaluation-form-box">
            <div class="width-half">
                <p>期次</p>
                <p>{{ runTimeDate.AutoCodeValue }}</p>
            </div>
            <div class="width-half">
                <p>设置时间</p>
                <p>{{ nowTime }}</p>
            </div>
            <div class="width-half">
                <p>开始生效时间</p>
                <p>
                    <el-date-picker
                        prefix-icon="1"
                        v-model="startTime"
                        type="datetime"
                        placeholder="请选择开始时间"
                        value-format="yyyy-MM-dd HH:mm"
                    >
                    </el-date-picker>
                </p>
            </div>
            <div class="width-half">
                <p>结束生效时间</p>
                <p>
                    <el-date-picker
                        prefix-icon="1"
                        v-model="endTime"
                        type="datetime"
                        placeholder="请选择结束时间"
                        value-format="yyyy-MM-dd HH:mm"
                    >
                    </el-date-picker>
                </p>
            </div>
            <div class="width-half">
                <p>规则设置部门</p>
                <p>{{ deptName }}</p>
            </div>
            <div class="width-half">
                <p>规则设置人</p>
                <p>{{ userName }}</p>
            </div>
            <div class="width-all">
                <p>备注</p>
                <el-input v-model="beizhu" placeholder="请输入"></el-input>
            </div>
        </div>
        <div class="credit-evaluation-form-message">
            <div class="message-header">
                <h1>评价维度</h1>
                <p>是否启用</p>
                <p>评价规则设置</p>
                <p>综合评分权重</p>
            </div>
            <div class="message-content">
                <div class="this-is-a-box" v-for="(item, index) in evaluateList" :key="index">
                    <h1>{{ item.name }}</h1>
                    <p>
                        <el-checkbox @change="changeCheck" v-model="item.is_enabled"></el-checkbox>
                    </p>
                    <p>
                        <el-button :disabled="!item.is_enabled || getCreditStatus" @click="setEvaluateRule(item)">
                            设置
                        </el-button>
                    </p>
                    <p>
                        <el-input @change="setEvaluateValue" v-model="item.weight"></el-input>%
                    </p>
                </div>
                <div class="this-is-a-box">
                    <h1>合计</h1>
                    <p></p>
                    <p></p>
                    <p>
                        <el-input readonly v-model="totalScore"></el-input>%
                    </p>
                </div>
            </div>
            <div class="message-bottom-button">
                <el-button @click="saveMessage" type="primary">
                    保存
                </el-button>
                <el-button @click="closeBoxWindow">
                    取消
                </el-button>
            </div>
        </div>
        <evaluateWindow
            v-if="showEvaluateWindow"
            :current-data="currentData"
            :run-time-date="runTimeDate"
            @closeWindow="closeWindow"
        >
        </evaluateWindow>
    </div>
</template>

<script>
import evaluateWindow from './tableOfEvaluationRules/index';
export default {
    data() {
        return {
            beizhu: '', // 备注
            startTime: '', // 开始时间
            endTime: '', // 结束时间
            totalScore: '', // 合计值
            showEvaluateWindow: false, // 是否显示评价弹窗
            currentData: {}, // 当前选中数据
            evaluateList: [],
            runTimeDate: {}, // 获取期数
            nowTime: '',
            deptName: '',
            userName: '',
            getCreditStatus: true,
            modifyNo: '',
        };
    },
    props: {
        extr: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    components: {
        evaluateWindow,
    },
    mounted() {
        this.nowTime = this.timeFormat(new Date());
        this.deptName = this.$takeTokenParameters('DeptName');
        this.userName = this.$takeTokenParameters('Name');
        this.getCredit();
    },
    methods: {
        // 时间转化
        timeFormat(date) {
            const y = date.getFullYear(); // 年
            let m = date.getMonth() + 1; // 月
            let d = date.getDate(); // 日
            let h = date.getHours(); // 时
            let mi = date.getMinutes(); // 分
            let s = date.getSeconds(); // 秒
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            h = h > 9 ? h : '0' + h;
            mi = mi > 9 ? mi : '0' + mi;
            s = s > 9 ? s : '0' + s;
            return `${y}-${m}-${d} ${h}:${mi}:${s}`;
        },
        // 关闭弹窗
        closeBoxWindow() {
            window.hideToast();
        },
        closeWindow() {
            this.showEvaluateWindow = false;
        },
        // 点击保存
        saveMessage() {
            if (this.totalScore !== 100) {
                this.$message.error('请输入正确的权重');
            } else if (!this.startTime) {
                this.$message.error('请选择开始生效时间');
            } else if (!this.endTime) {
                this.$message.error('请选择结束生效时间');
            } else {
                this.creditConfig();
            }
        },
        // 添加资信评价配置
        creditConfig() {
            const addCredit = {
                no: this.runTimeDate.AutoCodeValue,
                begin_time: this.startTime,
                end_time: this.endTime,
                remarks: this.beizhu,
                auto_index: this.runTimeDate.AutoIndex,
                items: this.evaluateList,
            };
            this.$axios
                .post('/interfaceApi/sale/credit_config/add_credit', addCredit)
                .then(() => {
                    this.evaluateList = [];
                    window.updateTable();
                    setTimeout(() => {
                        this.getCredit(this.runTimeDate.AutoCodeValue);
                    }, 200);
                    this.$message.success('保存成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 拉取资信评价配置信息  修改/评价
        getCredit(codeVal) {
            let creditNos;
            if (codeVal) {
                creditNos = codeVal;
            } else {
                creditNos = this.extr.creditNo ? this.extr.creditNo : 'creditNo';
            }
            this.$axios
                .get(`/interfaceApi/sale/credit_config/get_credit/${creditNos}/0`)
                .then(res => {
                    this.getCreditStatus = res ? false : true;
                    if (!res) {
                        this.autocodeRuntime();
                        this.getCreditSupply();
                    } else {
                        let newTotal = 0;
                        this.startTime = res.begin_time;
                        this.endTime = res.end_time;
                        this.runTimeDate.AutoCodeValue = res.no;
                        res.items.forEach((item, index) => {
                            const obj = {
                                id: item.id,
                                name: item.name,
                                code: item.code,
                                sort_num: item.sort_num,
                                is_enabled: item.is_enabled,
                                credit_id: item.credit_id,
                                weight: item.weight,
                            };
                            this.evaluateList.push(obj);
                            newTotal += Number(this.evaluateList[index].weight) * 1000;
                        });
                        this.totalScore = newTotal / 1000;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 拉取评价维度列表  新增
        getCreditSupply() {
            this.$axios
                .get('/interfaceApi/sale/credit_config/get_credit_supply')
                .then(res => {
                    // this.evaluateList = res;
                    res.forEach(item => {
                        const obj = {
                            name: item.dic_name,
                            code: item.dic_code,
                            sort_num: item.sort_num,
                            is_enabled: true,
                            weight: 0,
                        };
                        this.evaluateList.push(obj);
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 获取期数
        autocodeRuntime() {
            this.$axios
                .get('/interfaceApi/basicplatform/autocode/runtime?code=CreditNo')
                .then(res => {
                    this.runTimeDate = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 设置合同值
        setEvaluateValue() {
            let newTotal = 0;
            for (let i = 0; i < this.evaluateList.length; i++) {
                if (Number(this.evaluateList[i].weight) < 0 || Number(this.evaluateList[i].weight) > 100) {
                    this.$message.warning('请输入正确权重');
                } else if (this.evaluateList[i].is_enabled) {
                    newTotal += Number(this.evaluateList[i].weight) * 1000;
                }
            }
            this.totalScore = newTotal / 1000;
        },
        changeCheck() {
            let newTotal = 0;
            for (let i = 0; i < this.evaluateList.length; i++) {
                if (Number(this.evaluateList[i].weight) < 0 || Number(this.evaluateList[i].weight) > 100) {
                    this.$message.warning('请输入正确权重');
                } else if (this.evaluateList[i].is_enabled) {
                    newTotal += Number(this.evaluateList[i].weight) * 1000;
                } else if (!this.evaluateList[i].is_enabled) {
                    this.evaluateList[i].weight = 0;
                }
            }
            this.totalScore = newTotal / 1000;
        },
        // 设置合同规则
        setEvaluateRule(item) {
            this.currentData = item;
            this.showEvaluateWindow = true;
        },
    },
};

</script>
<style lang='stylus'>
.credit-evaluation-form
    padding .2rem
.credit-evaluation-form-box
    width 100%
    display flex
    flex-wrap: wrap;
    input::-webkit-input-placeholder
        font-size .16rem
    .width-half
        width 50%
        display flex
        height .45rem
        border-bottom 1px solid #ccc
        border-left 1px solid #ccc
        &:nth-child(1)
            border-top 1px solid #ccc
        &:nth-child(2)
            border-top 1px solid #ccc
        &:nth-child(even)
            border-right 1px solid #ccc
        p:first-child
            min-width 2.4rem
            max-width 2.4rem
            display flex
            align-items center
            font-size .16rem
            border-right 1px solid #ccc
            background #f6f8fc
            color #092d8e
            justify-content center
        p:last-child
            flex 1
            display flex
            font-size .16rem
            margin-left .2rem
            align-items center
        .el-input
            width 100% !important
            height .43rem
            .el-input__inner
                height .43rem
                border none
                font-size .16rem
                padding-left 0
    .width-all
        width 100%
        border-bottom 1px solid #ccc
        display flex
        height .45rem
        align-items center
        p:first-child
            width 2.41rem
            border-right 1px solid #ccc
            height 100%
            font-size .16rem
            background #f6f8fc
            display flex
            align-items center
            color #092d8e
            justify-content center
            border-left 1px solid #ccc
        .el-input
            border-right 1px solid #ccc
            flex 1
            height 100%
            input
                border none
                height .43rem
                font-size .16rem
.credit-evaluation-form-message
    width 100%
    h1
        border-left 1px solid #ccc
        width 2.41rem
        border-right 1px solid #ccc
        display flex
        align-items center
        justify-content center
        font-size .16rem
        height 100%
    p
        flex 1
        display flex
        height 100%
        align-items center
        justify-content center
        font-size .16rem
        border-right 1px solid #ccc
    .message-header
        height .45rem
        border-bottom 1px solid #ccc
        display flex
        h1
            background #d9e2f3
            color #092d8e
        p
            background #d9e2f3
            color #092d8e
    .message-content
        display flex
        flex-direction column
        .this-is-a-box
            height .45rem
            border-bottom 1px solid #ccc
            display flex
            align-items center
            justify-content center
            p:nth-child(3)
                cursor pointer
                color #092d8e
                button
                    height .43rem
                    text-decoration underline
                    border none
                    font-size .16rem
                    &:hover
                        background none
           .el-input
                width .6rem
                input
                    padding 0
                    text-align center
                    font-size .16rem
                    height .28rem
                    line-height .28rem
    .message-bottom-button
        width 100%
        display flex
        justify-content center
        padding-bottom .4rem
        position absolute
        left 0
        bottom 0
        width 100%
        button
            width 2.5rem
            &:last-child
                margin-left 1rem
</style>